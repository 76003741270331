const Index = () => {
    return (
        <div id="about" className="section-about">            
             <div className="curtain-about">
                <div className="container">
                    <h2>About Us</h2>
                    <p>
                        <strong>VASLOGIC Global Business Solutions </strong>is a collaborative business engagement among its founding members; with an aggregated experience of  20 years in the fields of technology, sales, marketing, branding, management, and project management.
                        We offer diverse business solutions; all of which can be tailored precisely to your business needs.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Index;
