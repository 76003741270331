import HeaderHero from 'components/headerhero/Index';
import About from 'components/about/Index';
import Services from 'components/services/Index';
import Projects from 'components/projects/Index';
import Career from 'components/careers/Index';
import Contact from 'components/contact/Index';
import Footer from 'components/footer/Index';

function App() {
  return (
    <div className="app">
      <HeaderHero />
      <About />
      <Services />
      <Projects />
      <Career />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
