import Form from './Form';
import Business from './Business';

const Index = () => {
    return (
     <div id="contact" className="section-contact">
         <div className="container">
             <div className="contact">
                <div className="details">
                    <Business />
                    <Form />                    
                </div>
             </div>
         </div>
     </div>
    )
 }
 
 export default Index;