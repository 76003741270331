import dev from 'images/services/yellow/dev.png';
import bpo from 'images/services/yellow/bpo.png';
import manage from 'images/services/yellow/manage.png';
import gateway from 'images/services/yellow/gateway.png';

interface IService {
    image: string,
    title: string,
    description: string,
    classname: string
}

const services: Array<IService> = [
    {
        image: gateway,
        title: 'Payment Gateway',
        description: 'We provide more options for customers to make online payments and web-based mobile payments secure, possible, and borderless.​',
        classname: 'gateway'
    },
    {
        image: dev,
        title: 'Tech Development',
        description: 'We create, develop, and design programs for web and mobile applications such as HR monitoring systems, payroll management, CRM, API, LRPs, streaming, 3D tours, and AR among many others.​',
        classname: 'dev'
    },
    {
        image: manage,
        title: 'Project Management',
        description: 'We create full-suite custom projects for clients who need to upgrade their business infra and processes. Hybrid accounts entail a combination of our services and technologies, all tailored to the clients\' needs.',
        classname: ''
    },

    {
        image: bpo,
        title: 'BPO',
        description: 'We serve international clients accommodating inbound and outbound calls, for sales and customer service needs. Our clients are in the industries of telecoms, medical care, e-learning products, manpower, energy, and finance, among others. ​',
        classname: ''
    },
]

const Index = () => {
   return (
    <div id="services" className="section-services">
        <div className="container">
            <div className="services">
                <h2>Full-Service Suite</h2>   
                <div className="list">
                    {
                        services.map((service, index) => {
                            return <Service service={service} key={index} />
                        })
                    }
                </div>
            </div>
        </div>
    </div>
   )
}

const Service = ({service}: {service: IService}) => {
    return (
        <div className="service">
            <div className="card">
                <img src={service.image} alt={service.title} className={service.classname}/>
                <h4>{service.title}</h4>
                <p>{service.description}</p>
            </div>
        </div>
    )
}

export default Index;