import buckdaddy from 'images/projects/buckdaddy.png';
import helix from 'images/projects/helix.png';
import vendy from 'images/projects/vendy.png';
import wallet from 'images/projects/dark/wallet.png';
import crm from 'images/projects/dark/crm.png';
import lms from 'images/projects/dark/lms.png';
import live from 'images/projects/dark/live.png';
import video from 'images/projects/dark/video.png';
import inventory from 'images/projects/dark/inventory.png';
import pim from 'images/projects/dark/pim.png';
import cart from 'images/projects/dark/cart.png';
import hotel from 'images/projects/dark/hotel.png';
import ticket from 'images/projects/dark/ticket.png';

import sms from 'images/projects/dark/sms.png';
import server from 'images/projects/dark/server.png';
import mailer from 'images/projects/dark/mailer.png';
import loyalty from 'images/projects/dark/loyalty.png';
import hosting from 'images/projects/dark/hosting.png';
import chat from 'images/projects/dark/chat.png';



interface IProject {
    image: string,
    title: string,
}

const featured: Array<IProject> = [
    {
        image: buckdaddy,
        title: 'BUCKDADDY'
    },
    {
        image: vendy,
        title: 'VENDY'
    },
    {
        image: helix,
        title: 'HELIX'
    },
]

const projects: Array<IProject> = [
    {
        image: wallet,
        title: 'Digital Wallet'
    },
    {
        image: crm,
        title: 'Customer Relationship Management'
    },
    {
        image: lms,
        title: 'Learning Management System'
    },
    {
        image: live,
        title: 'VOD & Live Streaming Platform'
    },
    {
        image: video,
        title: 'Video Calling Platform'
    },
    {
        image: inventory,
        title: 'Product Inventory System'
    },
    {
        image: pim,
        title: 'Product Information Management'
    },
    {
        image: cart,
        title: 'E-Commerce'
    },
    {
        image: hotel,
        title: 'Booking and Boarding Platform'
    },
    {
        image: ticket,
        title: 'Ticketing System'
    },
    {
        image: sms,
        title: 'SMS Sender and Forwarder'
    },
    {
        image: chat,
        title: 'Real-Time Messaging'
    },
    {
        image: mailer,
        title: 'Mailing Service'
    },
    {
        image: loyalty,
        title: 'Loyalty and Rewards'
    },
    {
        image: server,
        title: 'Server Management'
    },
    {
        image: hosting,
        title: 'Hosting Service'
    },
]

const Index = () => {
    return (
     <div id="projects" className="section-projects">
         <div className="container">
             <div className="projects">
                 <h2>Platforms and Project Features We Offer</h2>

                 <div className="platforms">
                    <div className="list">
                        {
                            projects.map((project, index) => {
                                return <Project project={project} key={index}/>
                            })
                        }
                    </div>
                 </div>
             </div>
         </div>

         <div className="wave">
            <svg className="wave-dark" preserveAspectRatio="xMaxYMin meet">
            <path className="wave-path wave-path-1" d="M1920 463.314V713H0V414.325C0.0515862 414.316 0.103105 414.308 0.154556 414.299C59.8232 467.769 270.664 573.445 636.676 568.392C873.316 565.126 1040.4 505.514 1188.57 452.65C1326.86 403.309 1448.68 359.846 1595.21 373.565C1727.41 385.944 1837.94 423.471 1920 463.314Z" fill="white"></path>
                    <path className="wave-path wave-path-2" d="M1920 463.988V713H0V234.66C102.275 199.182 221.353 170.486 354.422 158.026C584.381 136.494 775.567 204.707 992.611 282.146C1225.15 365.113 1373 457.092 1858.75 463.796C1879.49 464.082 1899.9 464.142 1920 463.988Z" fill="white"></path>
                    <path className="wave-path wave-path-3" d="M1920 85.9538V713H0V310.063C15.2357 310.099 30.6508 310.012 46.2454 309.796C417.632 304.67 679.852 211.113 912.389 128.146C1129.43 50.7068 1320.62 -17.5061 1550.58 4.02629C1690.21 17.1004 1814.43 48.0502 1920 85.9538Z" fill="white"></path>
            </svg>
        </div>
     </div>
    )
 }

 const Project = ({project}:{project: IProject}) => {
    return (
        <div className="card">
            <div className="img-circle">
                <img src={project.image} alt={project.title} />
            </div>
            <h4>{project.title}</h4>
        </div>
    )
 }
 
 export default Index;