import { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import * as Gi from 'react-icons/gi';
import * as Io from 'react-icons/io';
import logo from 'images/logo.png'

const scrollToTop = () => {
    scroll.scrollToTop();
};

const Header  = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!open);
    }

    return(
        <div className="container">
            <div className="header">
                <img src={logo} alt="logo" className="logo" onClick={scrollToTop}/>
                
                <div className="desktop">
                    <Menu handleOpen={handleOpen} />
                </div>

                <div className="mobile">                    
                    {
                        open ? 
                        <>
                            <Io.IoMdClose onClick={handleOpen}/>
                            <div className="mobile-nav">
                                <Menu handleOpen={handleOpen}/>
                            </div>
                        </> : <Gi.GiHamburgerMenu onClick={handleOpen} />
                    }
                </div>
            </div>
        </div>
    );
}

const Menu = ({handleOpen}:{handleOpen: Function}) => {
    return (
        <nav className="nav">
            <a onClick={scrollToTop}>Home</a>
            <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                onClick={() => handleOpen()}
            >
                About
            </Link>

            <Link
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                onClick={() => handleOpen()}
            >
                Services
            </Link>

            <Link
                activeClass="active"
                to="projects"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                onClick={() => handleOpen()}
            >
                Projects
            </Link>

            <Link
                activeClass="active"
                to="careers"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                onClick={() => handleOpen()}
            >
                Careers
            </Link>

            <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                onClick={() => handleOpen()}
            >
                Contact
            </Link>
        </nav>
    )
}

export default Header;