const Hero = () => {
    return (
        <div className="container">
            <div className="hero">
                <h1>Welcome to what's next</h1>
                <p>
                    We, at VASLOGIC, provide you the service
                    and competencies to help you optimize these
                    platforms <br/> efficiently and effectively; from
                    building a cost-efficient plan for your business
                    across all industries, <br/> and securing your
                    company’s competitive edge.
                </p>
            </div>
        </div>
    )
}

export default Hero;