const Index = () => {
    return (
     <div id="careers" className="section-career">
         <div className="container">
             <div className="career">
                 <h2>Join Our Team</h2>
                 <div className="card">
                    <p>
                        Submit your resume to
                        <a href="mailto:hrmd@vaslogicglobal.net">
                            hrmd@vaslogicglobal.net
                        </a> 
                        <br/>
                        Enter your name and desired position as email subject.
                    </p>
                 </div>
             </div>
         </div>
     </div>
    )
 }
 
 export default Index;