import React from 'react';
import { IconContext } from "react-icons";
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Cg from 'react-icons/cg';

const Business = () => {
    const handleOnClick = (e: React.FormEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    }

    return (
        <div className="business-card">
            <h2>Let's talk</h2>
            <h3>Is there a challenge your organization or company needs help solving? We’d love to discuss it.</h3>

            <div className="address">
                <h4>Address</h4>
                <p>Manila Philippines</p>
            </div>

            <div className="contacts">
                <div className="col">
                    <h4>Email Address</h4>
                    <p>hello@vaslogicglobal.net</p>
                </div>
                {/* <div className="col">
                    <h4>Telephone number</h4>
                    <p>+639123456878</p>
                </div> */}
            </div>

            <IconContext.Provider value={{ color: "white", className: "icons" }}>
                <div className="social">
                    <a href="#" onClick={e => handleOnClick(e)}>
                        <Cg.CgFacebook />
                    </a>
                    <a href="#" onClick={e => handleOnClick(e)}>
                        <Fa.FaLinkedin />
                    </a>
                    <a href="#" onClick={e => handleOnClick(e)}>
                        <Ai.AiOutlineTwitter />
                    </a>
                    <a href="#" onClick={e => handleOnClick(e)}>
                        <Fa.FaGooglePlusG />
                    </a>
                </div>
            </IconContext.Provider>
        </div>

    )
}

export default Business;