import { useState } from 'react';

import 'styles/app.scss';
import Header from './Header';
import Hero from './Hero';

const Index = () => {
    const [shadow, setShadow] = useState('');

    window.onscroll = () => {
        if(document.documentElement.scrollTop > 20) {
            setShadow('shadow');
        }else {
            setShadow('');
        }
    }

    return (
        <>
            <div className={`section-header ${shadow}`}>
                <Header />
            </div>

            <div className="section-hero">
                <Hero />            
            </div>
        </>
    );
}

export default Index;