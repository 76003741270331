import { IconContext } from "react-icons";
import * as Fa from 'react-icons/fa';
import * as Ai from 'react-icons/ai';
import * as Cg from 'react-icons/cg';
import logo from 'images/logo.png'

const Index = () => {
    const handleOnClick = (e: React.FormEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    }

    const date = new Date();
    const year = date.getFullYear();

    return (
     <div className="section-footer">
         <div className="container">
             <div className="footer">
                 <div className="brand col">
                    <img src={logo} alt="logo" className="logo"/>
                    <p>
                        Our business solutions are tailored precisely to your business needs. Let's work together!
                    </p>
                    <br />
                 </div>

                 <div className="quicklinks col">
                    <IconContext.Provider value={{ color: "white", className: "icons" }}>
                        <div className="social">
                            <a href="#" onClick={e => handleOnClick(e)}>
                                <Cg.CgFacebook />
                            </a>
                            <a href="#" onClick={e => handleOnClick(e)}>
                                <Fa.FaLinkedin />
                            </a>
                            <a href="#" onClick={e => handleOnClick(e)}>
                                <Ai.AiOutlineTwitter />
                            </a>
                            <a href="#" onClick={e => handleOnClick(e)}>
                                <Fa.FaGooglePlusG />
                            </a>
                        </div>
                    </IconContext.Provider>
                 </div>
             </div>
             <small>VASLOGIC GLOBAL BUSINESS SOLUTIONS &copy; {year}</small>
         </div>
     </div>
    )
 }
 
 export default Index;