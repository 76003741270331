import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface IError {
    hasError: boolean,
    message: string
}

interface IFormData {
    fullname: string,
    email: string,
    phone: string,
    company: string,
    message: string
}

const Form = () => {
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<IError>({
        hasError: false,
        message: ''
    })

    const reRef:any = useRef<ReCAPTCHA>();
    const key = process.env.REACT_APP_RECAPTCHA_KEY as string;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        
        if(token === null) {
            setError({
                hasError: true,
                message: 'Please validate the captcha before submitting your request.'
            });
            setIsLoading(false);
            return;
        }

        const target = e.target as any;
        const formData: IFormData = {
            fullname: target.elements.name.value,
            email: target.elements.email.value,
            phone: target.elements.phone.value,
            company: target.elements.company.value,
            message: target.elements.message.value
        }

        const url = process.env.REACT_APP_API_URL + 'inquiry';
        const headers: any = {
            'Content-Type': 'application/json',
            'X-AppKey': process.env.REACT_APP_APPLICATION_KEY,
            'X-Token': token
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(formData)

        })

        const json  = await response.json();
        
        if(!json.success) {
            console.log(json);
        }

        //reset everything
        setToken(null);
        reRef.current.reset();
        target.reset();
        setIsLoading(false);
        setError({hasError: false, message: ''});
    }

    const handleToken = (token:any) => {
        setToken(token);
    }

    const handleExpire = () => {
        setToken(null);
        setIsLoading(false);
    }

    return (
        <form className="form-contact" onSubmit={e => handleSubmit(e)}>
            {
                error.hasError ? <Error message={error.message} /> : null
            }

            <div className="form-group">
                <label htmlFor="name" className="form-label">
                    Full Name <span className="required">*</span>
                </label>
                <input type="text" name="name" id="name" maxLength={50} className="form-control" required />
            </div>

            <div className="form-group">
                <label htmlFor="email" className="form-label">
                    Email Address <span className="required">*</span>
                </label>
                <input type="email" name="email" id="email" maxLength={50} className="form-control" required/>
            </div>

            <div className="form-group">
                <label htmlFor="phone" className="form-label">
                    Phone Number <span className="required">*</span>
                </label>
                <input type="text" name="phone" id="phone" maxLength={13} className="form-control" required/>
            </div>

            <div className="form-group">
                <label htmlFor="company" className="form-label">
                    Company <small>(Optional)</small>
                </label>
                <input type="text" name="company" id="company" maxLength={50} className="form-control" />
            </div>

            <div className="form-group">
                <label htmlFor="message" className="form-label">
                    Message <span className="required">*</span>
                </label>
                <textarea name="message" id="message" className="form-control" maxLength={500} cols={30} rows={8} required></textarea>
            </div>

            <div className="form-action-group">
                <ReCAPTCHA 
                    ref={reRef} 
                    sitekey={key} 
                    size="normal"
                    onChange={e => handleToken(e)}
                    onExpired={handleExpire}
                />
                <button className={`btn-submit ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
                    {isLoading ? 'Please wait..' : 'Submit'}
                </button>    
            </div>
        </form>
    );
}

const Error = ({message}: {message: string}) => {
    return (
        <div className="error">
            {message}
        </div>
    )
}

export default Form;